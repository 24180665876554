module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'Ronny Unger', // Navigation and Site Title
  siteTitleAlt: 'Ronny Unger - Experienced Software and Systems Engineer', // Alternative Site title for SEO
  siteUrl: 'https://portfolio-bella.netlify.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logos/logo-1024.png', // Used for SEO and manifest
  siteDescription:
    'Ronny Unger is a SaaS software and systems engineer and experienced CTO who has spent the' +
    ' last 15 years working hands-on across the stack and helping teams to build great products.',
  author: 'Ronny Unger', // Author for schemaORGJSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@php-workx', // Twitter Username
  ogSiteName: 'phpworkx', // Facebook Site Name
  ogLanguage: 'en_US', // Facebook Language

  // Manifest and Progress color
  themeColor: '#b710a1',
  backgroundColor: '#350d27',
};
